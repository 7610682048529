<template>
  <v-card min-width="250px" height="300px" flat class="rounded-lg">
    <v-card-title class="text-color-title caption font-weight-bold py-1">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn icon @click="menuClick()">
        <v-icon>mdi-menu-open</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <div class="text-color-main">
        <div class="text-h6 font-weight-black">
          {{ citation.verse }}
        </div>
        <div class="caption">
          {{ citation.text }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    title: "DEVOTION"
  }),
  props: {
    devotion: {
      type: Object,
      default: null
    }
  },
  computed: {
    citation() {
      if (this.devotion) {
        return {
          verse: this.devotion.verse,
          text: this.devotion.verse_text
        };
      }

      return {};
    }
  }
};
</script>
